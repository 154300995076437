export class ClassOfUser {
    constructor(idClass, nameClass = '', listCriteria = []) {
        this.id = idClass;
        this.nameClass = nameClass;
        this.ListCriteria = listCriteria.filter(item => item && item !== 'null').map((criteria) => {
            return new CriteriaOfClass(
                this.checkTitleCard(criteria.type),
                this.checkStandard(criteria.type),
                criteria.pass,
                criteria.fail,
                this.checkNameGreenPart(criteria.type),
                this.checkNameRedPart(criteria.type),
                (criteria.pass + criteria.fail !== 0) ? Math.floor(criteria.data * 100) / 100 : 100,
                criteria.type,
                idClass,
            )
        }).filter(criteria => criteria !== null)
    }
    checkNameGreenPart(type) {
        if(Math.floor(type) == 3) {
            return 'Pass'
        }
        switch (type) {
            case 1:
                return 'Đi học';
            case 2:
                return 'Hoàn thành';
            case 4:
                return 'Ca hoàn thành';
            case 5:
                return 'Đạt';
        }
    }
    checkNameRedPart(type) {
        if(Math.floor(type) == 3) {
            return 'Fail'
        }
        switch (type) {
            case 1:
                return 'Nghỉ học';
            case 2:
                return 'Không hoàn thành';
            case 4:
                return 'Ca lỗi';
            case 5:
                return 'Không đạt';                
        }
    }
    checkStandard(type) {
        if(Math.floor(type) == 3) {
            return '75'
        }
        switch (type) {  
            case 1:  
              return '90';  
            case 2:  
            case 5:  
              return '70';  
          }  
    }
    checkTitleCard(type) {
        if(Math.floor(type) == 3) {
            return this.checkTest(type);
        }
        switch (type) {          
            case 1:
                return 'Chuyên cần'
            case 2:
                return 'Làm BTVN'
            case 4:
                return 'Lỗi giảng dạy'
            case 5:
                return 'BTVN đạt tiêu chuẩn'    
        }
    }

    checkTest(type){
        let name_type = '';
        if(type > 3) {
            let decimalPart = (type - Math.floor(type)).toFixed(1) * 10;
            decimalPart = Math.round(decimalPart);
            name_type = 'Mini Test ' + decimalPart;
        } else {
            name_type = 'Final Test'
        }
        return name_type;
    }
}

class CriteriaOfClass {
    constructor(nameCriteria = '', standard, greenPart = 0, redPart = 0, nameGreenPart, nameRedPart, percentPass, typeCard, classId) {
        this.nameCriteria = nameCriteria
        this.standard = standard;
        this.nameGreenPart = nameGreenPart;
        this.greenPart = greenPart;
        this.percentGreenPart = percentPass;
        this.nameRedPart = nameRedPart;
        this.redPart = redPart;
        this.percentRedPart = Math.floor((100 - percentPass) * 100) / 100;
        this.type = typeCard;
        this.classId = classId;
    }
}

export const statusClass = [
    {
        name: 'Đang học',
        value: 2
    },
    {
        name: 'Đã học',
        value: 3
    }
]

export const headerModalDiligence = ['STT', 'Tên học viên', 'Tổng số buổi nghỉ', 'Có phép', 'Không phép']
export const headerModalErrorLesson = ['STT', 'Buổi học', 'Lỗi']
export const headerModalHomework = ['STT', 'Tên học viên', 'Tổng số buổi không làm BTVN']
export const headerModalTest = ['STT', 'Tên học viên', 'Điểm trung bình']
export const headerModalMeetRequirements = ['STT','Tên học viên','Tổng số buổi không đạt tiêu chuẩn','Không làm BTVN','BTVN điểm thấp']