<script>
import DoughnutChart from "./DoughnutChart.vue";
import {GET_DETAIL_CLASS_INFO} from "@/core/services/store/supportClass/supportClass.module";
import TableModal from "@/views/pages/home/component/TableModal.vue";
import {
  headerModalDiligence,
  headerModalErrorLesson,
  headerModalHomework,
  headerModalTest,
  headerModalMeetRequirements
} from "@/views/pages/home/model/ClassOfUser";

export default {
  name: "CriteriaOfClass",
  components: {TableModal, DoughnutChart},
  props:{
    criteria:{
      type:Object,
      default(){
        return {}
      }
    },
    index:{
      type: String,
      default() {
        return '';
      }
    }
  },
  watch: {
    dialogVisible(to, from) {
      if (Math.floor(this.criteria.type) == 3) {
        this.tableHeader = this.headerTest
        this.titleModal = 'Danh sách học viên fail'
      }
      switch (this.criteria.type) {
        case 1:
          this.titleModal = 'Danh sách học viên nghỉ học'
          this.tableHeader = this.headerDiligence
          break;
        case 2:
          this.titleModal = 'Danh sách học viên không làm BTVN'
          this.tableHeader = this.headerHomework
          break;
        case 4:
          this.titleModal = 'Danh sách ca lỗi giảng dạy'
          this.tableHeader = this.headerErrorLesson
          break;
        case 5:
        this.titleModal = 'Danh sách học viên không đạt tiêu chuẩn BTVN'
        this.tableHeader = this.headerMeetRequirements
        break;
      }
    },
  },
  mounted() {
  },
  data(){
    return {
      dialogVisible : false,
      titleModal: '',
      dataTable: [],
      tableHeader: [],
      headerDiligence: headerModalDiligence,
      headerErrorLesson: headerModalErrorLesson,
      headerTest: headerModalTest,
      headerMeetRequirements: headerModalMeetRequirements,
      headerHomework: headerModalHomework,
      loadingDetail: true
    }
  },
  methods: {
    async getListDetailClassInfo() {
      this.dialogVisible = true;
      this.loadingDetail = true;
      const payload = {
        class_id: this.criteria.classId,
        type: this.criteria.type,
      }
      const response = await this.$store.dispatch(GET_DETAIL_CLASS_INFO, payload)
      this.dataTable = response?.data?.data ?? []
      this.loadingDetail = false;
    }
  }
}
</script>

<template>
  <div class="card p-3 mb-2" style="border-color:#000000" >
    <h1 style="font-size: 15px; font-weight: 500 !important;">{{criteria?.nameCriteria}}</h1>
    <div class="d-flex justify-content-center">
      <doughnut-chart :criteria="criteria" :index="index"></doughnut-chart>
    </div>
    <div class="mt-4">
      <div >
        <i class="fa-solid fa-circle" style="color: #38A169"></i>
        <span class="ml-3" style="font-size: 14px">{{criteria?.nameGreenPart}}</span>
        <span class="ml-5" style="font-weight: bold">{{criteria?.greenPart}}</span>
      </div>
      <div class="mt-3">
        <i class="fa-solid fa-circle" style="color: #E53E3E"></i>
        <span class="ml-3" style="font-size: 14px">{{criteria?.nameRedPart}}</span>
        <span class="ml-5" style="font-weight: bold">{{criteria?.redPart}}</span>
      </div>
    </div>
    <div style="text-align: right" class="mt-3" >
      <span
          style="color: #1A44AE;text-decoration: underline;cursor: pointer;font-size: 13px;user-select: none"
          @click="getListDetailClassInfo"
      >
        Xem chi tiết
      </span>
    </div>
    <el-dialog
        :visible.sync="dialogVisible"
        :title="titleModal"
        width="500"
        :show-close="false"
    >
      <hr style="margin-top: -20px"/>
      <TableModal
          :loading = "loadingDetail"
          :data-table="dataTable"
          :type-card="criteria.type"
          :table-header="tableHeader"
      />
      <div class="d-flex justify-content-end mt-4">
        <button type="button" class="btn btn-secondary" @click="dialogVisible = false">Đóng</button>
      </div>
    </el-dialog>
  </div>
</template>

<style scoped>

</style>